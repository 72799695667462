import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from "../store/index"
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/pages/login',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/employees',
        name: 'Employees',
        component: () => import('@/views/admin/employees.vue'),
      },
      {
        path: '/rooms',
        name: 'Rooms',
        component: () => import('@/views/admin/rooms.vue'),
      },
      {
        path: '/clients',
        name: 'Clients',
        redirect: '/clients/clients',
        children: [
          {
            path: '/clients/clients',
            component: () => import('@/views/admin/clients.vue'),
          },
          {
            path: '/clients/clientDashboard',
            name: 'ClientDashboard',
            redirect: '/clients/clientDashboard/clientDashboard',
            children: [
              {
                path: '/clients/clientDashboard/clientDashboard',
                component: () => import('@/views/admin/clientDashboard.vue'),
              },
              {
                path: '/clients/clientDashboard/Locations',
                component: () => import('@/views/admin/locations.vue'),
              },
              {
                path: '/clients/clientDashboard/locationDashboard',
                name: 'LocationDashboard',
                children: [
                  {
                    path: '/clients/clientDashboard/locationDashboard',
                    component: () =>
                      import('@/views/admin/locationDashboard.vue'),
                  },
                  {
                    path: '/clients/clientDashboard/roomDashboard',
                    name: 'RoomDashboard',
                    children: [
                      {
                        path: '/clients/clientDashboard/roomDashboard',
                        component: () =>
                          import('@/views/admin/roomDashboard.vue'),
                      },
                      {
                        path: '/clients/clientDashboard/taskListDashboard',
                        name: 'TaskListDashboard',
                        component: () =>
                          import('@/views/admin/taskListDashboard.vue'),
                      },
                      {
                        path: '/clients/clientDashboard/taskLog',
                        name: 'TaskLog',
                        component: () =>
                          import('@/views/admin/taskLog.vue'),
                      },
                    ],
                  },

                ],
              },
            ],
          },
        ],
      },

      {
        path: '/taskLists',
        name: 'Task Lists',
        component: () => import('@/views/admin/task.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/ClientsEmployees',
        name: 'Clients Employee',
        children: [
          {
            path: '/ClientsEmployees',
            component: () => import('@/views/employees/clientss.vue'),
          },
          {
            path: '/clientDashboardEmployee/ClientsDashboard',
            name: 'ClientsDashboard',
            children: [
              {
                path: '/clientDashboardEmployee/ClientsDashboard',
                component: () =>
                  import('@/views/employees/clientsDashboard.vue'),
              },
              {
                path: '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee',
                name: 'locationDashboard',
                component: () => import('@/views/employees/locationsDashboard.vue'),
              },
              {
                path: '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom',
                name: 'taskRoom',
                component: () => import('@/views/employees/taskRoom.vue'),
              },
              {
                path: '/clientDashboardEmployee/ClientsDashboard/locationDashboardEmployee/taskRoom/taskListDetails',
                name: 'taskListDetails',
                component: () => import('@/views/employees/taskListDetails.vue'),
              },
            ],
          },
        ],
      },



      {
        path: '/tasksRoom',
        name: 'tasksRoom',
        component: () => import('@/views/employees/taskRoom.vue'),
      },
      {
        path: '/tasklog',
        name: 'tasklog',
        component: () => import('@/views/employees/tasklog.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttonss',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },

      /// Users
      {
        path: '/employeesUsers',
        name: 'Employees ',
        component: () => import('@/views/user/employees.vue'),
      },
      {
        path: '/clientsUser',
        name: 'Clients ',
        redirect: '/clientsUser/clientsUser',
        children: [
          {
            path: '/clientsUser/clientsUser',
            component: () => import('@/views/user/clients.vue'),
          },
          {
            path: '/clientsUser/clientDashboardUser',
            name: 'ClientDashboard ',
            redirect: '/clientsUser/clientDashboardUser/clientDashboardUser',
            children: [
              {
                path: '/clientsUser/clientDashboardUser/clientDashboardUser',
                component: () => import('@/views/user/clientDashboard.vue'),
              },
              {
                path: '/clientsUser/clientDashboardUser/locationDashboardUser',
                name: 'LocationDashboard ',
                children: [
                  {
                    path: '/clientsUser/clientDashboardUser/locationDashboardUser',
                    component: () =>
                      import('@/views/user/locationDashboard.vue'),
                  },
                  {
                    path: '/clientsUser/clientDashboardUser/roomDashboardUser',
                    name: 'RoomDashboard ',
                    children: [
                      {
                        path: '/clientsUser/clientDashboardUser/roomDashboardUser',
                        component: () =>
                          import('@/views/user/roomDashboard.vue'),
                      },
                      {
                        path: '/clientsUser/clientDashboardUser/taskListDashboardUser',
                        name: 'TaskListDashboard ',
                        component: () =>
                          import('@/views/user/taskListDashboard.vue'),
                      },
                      {
                        path: '/clientsUser/clientDashboardUser/taskLogUser',
                        name: 'TaskLog ',
                        component: () =>
                          import('@/views/user/taskLog.vue'),
                      },
                    ],
                  },

                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/views/pages/ForgotPassword'),
      },
      {
        path: 'password-reset',
        name: 'PasswordReset',
        component: () => import('@/views/pages/PasswordReset'),
      },
      {
        path: 'publicQr',
        name: 'PublicQr',
        component: () => import('@/views/pages/publicQr'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: 'adminAccess',
        name: 'adminAccess',
        component: () => import('@/views/admin/sa-access.vue'),
      },
    ],
  },
  {
    path: '/admin',
    redirect: '/pages/404',
    name: 'Admin',
    component: DefaultLayout,
    children: [
      {
        path: 'clients',
        name: 'clientsSuperAdmin',
        component: () => import('@/views/superAdmin/clients.vue'),
      },
      {
        path: 'employees',
        name: 'employeesSuperAdmin',
        component: () => import('@/views/superAdmin/employees.vue'),
      }
    ]

  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/pages/login',
    '/pages/adminAccess',
    '/pages/404',
    '/pages/500',
    '/pages/forgotPassword',
    '/pages/password-reset',
    '/pages/publicQr',
    '/pages/register',
  ]; // Rutas accesibles sin autenticación

  const authRequired = !publicPages.includes(to.path); // Verificar si la autenticación es necesaria
  const loggedIn = store.state.isAuthenticated; // Verificar si el usuario está autenticado
  console.log(to);

  if (!authRequired) {
    // Si la ruta no requiere autenticación, permitir el acceso
    next();
  } else if (authRequired && !loggedIn) {
    // Si la ruta requiere autenticación y el usuario no está autenticado, redirigir al login
    next({ path: '/pages/login'});
  } else {
    // En cualquier otro caso, permitir la navegación
    next();
  }
});




export default router
